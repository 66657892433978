import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const VulnerabilityManagement = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="VulnerabilityManagement"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">With the fast-changing trends in the exploitation of vulnerabilities, organizations need to understand the business risks associated with vulnerabilities and prioritize the vulnerabilities first which pose the highest risk to their business.</p>
                        <p className="mt-3">TEQT uses its proprietary MASTER “Managed Automated Security Testing & Enhanced Remediation” framework to provide integrated vulnerability management services to our customers.</p>
                        <p className="mt-2">MASTER helps organizations go beyond basic scanning to define risks that are contextualized to business, and analyze scan results that are powered with a predictive vulnerability intelligence platform.</p>
                        <p className="mt-2">MASTER also helps CISOs with a centralized reporting portal for visibility on risks and aging on vulnerabilities. TEQT analysts coordinate remediation activities end-to-end to reduce the overall risk and reduce downtime.</p>
                        <p className="mt-2">Through our MASTER framework, we help customers in identifying the possible entry points, discover and categorize assets according to their criticality to customers’ business, and efficiently remediate the identified risks and vulnerabilities.</p>

                        <h6>The features of MASTER include:</h6>

                        <ul className="circle-list">
                        <li className="mt-2"><span className="text-black">Discovery: </span>Automated asset discovery covering new assets</li>
                        <li className="mt-2"><span className="text-black">Asset Classification and Grouping: </span>Severity report mapped to business context</li>
                        <li className="mt-2"><span className="text-black">Threat & Vulnerability Detection: </span>Comprehensive detection methods covering internal & external IT assets and applications. </li>
                        <li className="mt-2"><span className="text-black">Comprehensive Reports: </span>White-labeled reports based on client and compliance requirements </li>
                        <li className="mt-2"><span className="text-black">Remediation Tracking Verification</span></li>
                        <li className="mt-2"><span className="text-black">Verification: </span>Active post-remediation verification & threat monitoring </li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query VulnerabilityManagementPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

VulnerabilityManagement.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default VulnerabilityManagement;
